<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false"> Ok </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 text-center pt-6 pb-6>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px"
          >UPDATE UNITS
        </span>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap justify-center v-if="pendingList.length > 0">
      <v-flex xs12 sm10 text-center pb-6>
        <v-layout wrap pb-5>
          <span
            style="font-family: sedanRegular; color: #000000; font-size: 18px"
            >Incomplete Asset
          </span>
        </v-layout>
        <v-card outlined>
          <v-layout wrap justify-start py-2>
            <v-flex xs2 sm1 pa-2 v-for="(item, i) in pendingList" :key="i">
              <v-chip
                outlined
                color="#464746"
                v-if="item.assetID"
                @click="getView(item._id)"
                style="font-family: sedanRegular; color: #000; cursor: pointer"
                >{{ item.assetID }}</v-chip
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout> -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout wrap justify-center>
        <v-flex xs10 sm10 md10 text-left>
          <span
            style="font-family: sedanRegular; color: #000000; font-size: 18px"
            >ASSET DETAILS
          </span>
          <v-layout wrap>
            <v-flex xs12 pt-4>
              <v-card>
                <v-layout wrap justify-start py-2 pb-5 pa-5>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Asset ID</span
                    >
                    <v-text-field
                      outlined
                      dense
                      disabled
                      ref="id"
                      :rules="rules"
                      v-model="assetDetails.assetID"
                      placeholder="Unique reference code"
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Asset Type</span
                    >
                    <v-select
                      outlined
                      dense
                      :rules="rules"
                      v-model="assetDetails.assetType"
                      :items="assetArray"
                      placeholder="Choose from list"
                    ></v-select>
                  </v-flex> -->
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Asset Name</span
                    >
                    <v-text-field
                      :rules="rules"
                      outlined
                      disabled
                      v-model="assetDetails.assetName"
                      dense
                      placeholder="Short name for the asset"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Asset-Description</span
                    >
                    <!-- <v-textarea
                      :rules="rules"
                      outlined
                      dense
                      v-model="assetDetails.description"
                      placeholder="Brief description of the asset that can be viewed by the public"
                    ></v-textarea> -->
                    <div id="app">
                      <vue-editor
                        v-model="assetDetails.description"
                      ></vue-editor>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs10 sm10 md10 text-left pt-10>
          <span
            style="font-family: sedanRegular; color: #000000; font-size: 18px"
            >UNIT DETAILS
          </span>
          <v-layout wrap>
            <v-flex xs12 pt-4>
              <v-card>
                <v-layout wrap justify-start py-2 pb-5 pa-5>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Unit Holder Entity Name</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      :rules="rules"
                      v-model="assetDetails.unitHolderEntityName"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Unit Holder Code</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      v-model="assetDetails.unitHolderCode"
                      dense
                    ></v-text-field>
                  </v-flex> -->
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Units Total Supply</span
                    >
                    <!-- <v-select :hide-details="true" v-model="state" outlined dense></v-select> -->
                    <v-text-field
                      :hide-details="true"
                      outlined
                      v-model="assetDetails.unitsTotalSupply"
                      dense
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Units Blocked</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      dense
                      type="number"
                      v-model="assetDetails.unitsBlocked"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Units Buffered</span
                    >
                    <v-text-field
                      :hide-details="true"
                      outlined
                      dense
                      type="number"
                      v-model="assetDetails.unitsBuffered"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Creation Date</span
                    >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          outlined
                          v-model="assetDetails.creationDate"
                          append-icon="mdi-calendar"
                          readonly
                          :hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="assetDetails.creationDate"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-2>
                    <span
                      style="
                        font-family: sedanRegular;
                        color: #000000;
                        font-size: 15px;
                      "
                      >Expiry Date</span
                    >
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          outlined
                          v-model="assetDetails.expiryDate"
                          append-icon="mdi-calendar"
                          readonly
                          :hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="assetDetails.expiryDate"
                        @input="menu1 = false"
                        :min="assetDetails.creationDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-end py-5>
        <v-flex xs12 sm4 md4>
          <v-btn
            rounded
            color="#7CB9E8"
            dark
            :disabled="!valid"
            @click="validate()"
          >
            <span style="font-size: 12px; font-family: montserratSemiBold"
              >Save and Continue</span
            >
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  props: ["asset"],
  components: {
    VueEditor,
  },
  data: () => ({
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    assetDetails: {
      assetID: "",
      // assetType: "Units",
      assetName: "",
      description: "",
      unitHolderEntityName: "",
      // unitHolderCode: "",
      unitsTotalSupply: "",
      unitsBlocked: "",
      unitsBuffered: "",
      creationDate: "",
      expiryDate: "",
    },
    users:"",
    menu: false,
    menu1: false,
    valid: true,
    id: "",
    assetId: "",
    submittedLevels: [],
    assetArray: ["Units"],
    rules: [(v) => !!v || "Required"],
    pendingList: [],
  }),
  beforeMount() {
    this.$emit("stepper", {
      ref: "page1",
      assetDetails: this.assetDetails,
    });
    this.assetDetails = this.asset;
    this.getView();
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.saveData();
      } else window.scrollTo(0, 0);
    },
    saveData() {
      //   if (this.assetId) {
      //     this.assetDetails.asset_id = this.assetId;
      //   } else {
      //     this.assetDetails.asset_id = this.id;
      //   }
      //   axios({
      //     method: "POST",
      //     url: "/asset/register/units/level1",
      //     data: this.assetDetails,
      //     headers: {
      //       token: localStorage.getItem("token"),
      //     },
      //   })
      //     .then((response) => {
      //       this.appLoading = false;
      //       if (response.data.status) {
      //         this.msg = response.data.msg;
      //         this.id = response.data.data._id;
      //         this.showSnackBar = true;
      this.$emit("stepper", {
        ref: "page1Tab",
        level: "tab-2",
        subLevel: 1,
        assetDetails: this.assetDetails,
      });
      this.$emit("stepper", {
        ref: "assetGet",
        assetIdNew: this.id,
      });
      //   } else {
      //     this.msg = response.data.msg;
      //     this.showSnackBar = true;
      //   }
      // })
      // .catch((err) => {
      //   this.ServerError = true;
      //   console.log(err);
      // });
    },
    getView() {
      axios({
        method: "GET",
        url: "/asset/latest/transaction/view/" + this.$route.query.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.assetDetails = response.data.data;
            this.users=response.data.accessibleUsers
            this.assetDetails.creationDate =
              this.assetDetails.creationDate.slice(0, 10);
            this.assetDetails.expiryDate = this.assetDetails.expiryDate.slice(
              0,
              10
            );
            this.$emit("stepper", {
              ref: "managementPage",
              management: this.assetDetails,
              assetIdNew: this.$route.query.id,
              users:this.users
            });
            this.assetId = this.$route.query.id;
            this.submittedLevels = this.assetDetails.submittedLevels;
            console.log("sublevel", this.submittedLevels);
            this.$emit("stepper", {
              ref: "page1SubTab",
              subLevel: this.submittedLevels,
            });
            // var allArray = [1, 2];
            // var newArray = allArray.filter(
            //   (x) => !this.submittedLevels.includes(x)
            // );
            // if (newArray.length > 0) {
            //   if (newArray[0] == 1) {
            //     this.$emit("stepper", {
            //       ref: "page1Tab",
            //       level: "tab-1",
            //     });
            //   } else if (newArray[0] == 2) {
            //     this.$emit("stepper", {
            //       ref: "page1Tab",
            //       level: "tab-2",
            //     });
            //   }
            // } else {
            //   this.$emit("stepper", {
            //     ref: "page1Tab",
            //     level: "tab-3",
            //     check: newArray,
            //   });
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
