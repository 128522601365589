<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false"> Ok </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md8 text-center pt-6 pb-6>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px"
          >UPDATE UNITS
        </span>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap justify-start v-if="pendingList.length > 0">
      <v-flex xs10 sm3 text-center pa-2 pb-6>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px"
          >Incomplete Asset
        </span>
        <v-layout wrap justify-center py-2>
          <v-flex xs12 sm4 v-for="(item, i) in pendingList" :key="i">
            <span
              @click="getView(item._id)"
              style="font-family: sedanRegular; color: #9a9a9a; cursor: pointer"
              >{{ item.assetID }}</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout> -->
    <v-layout wrap justify-center px-10>
      <v-flex xs10 sm2 md2 text-center>
        <span style="font-family: montserratSemiBold; color: #000000; font-size: 15px"
          >{{ asset.assetID }}
        </span>
      </v-flex>
      <v-flex xs10 sm10 md10 text-left>
        <span style="font-family: montserratSemiBold; color: #000000; font-size: 15px"
          >{{ asset.assetName }}
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-10>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px"
          >OWNER BANK ACCOUNT DETAILS (PRIVATE)
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-start py-2 pb-5 pa-5>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner Bank Name</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    v-model="management.ownerBankName"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner BankBranch</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    v-model="management.ownerBankBranch"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 pa-2>
                  <span
                    style="
                      font-family: sedanRegular;
                      color: #000000;
                      font-size: 15px;
                    "
                    >Owner Bank Account Number</span
                  >
                  <v-text-field
                    :hide-details="true"
                    outlined
                    dense
                    v-model="management.ownerBankAccountNumber"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end py-5>
      <v-flex xs12 sm4 md4>
        <v-btn rounded color="#7CB9E8" dark @click="saveData()">
          <span style="font-size: 12px; font-family: montserratSemiBold"
            >Save and Continue</span
          >
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  props: ["asset", "manage", "assetIdNew"],
  data: () => ({
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    management: {
      ownerBankName: "",
      ownerBankBranch: "",
      ownerBankAccountNumber: "",
    },
    id: "",
    assetId: "",
    submittedLevels: [],
    pendingList: [],
  }),
  beforeMount() {
    this.$emit("stepper", {
      ref: "page2",
      management: this.management,
    });
    this.management = this.manage;
    this.assetId = this.assetIdNew;
    console.log("assetid", this.assetId);
    if (this.manage.projectStartDate) {
      this.management.projectStartDate = this.management.projectStartDate.slice(
        0,
        10
      );
      this.management.projectEndDate = this.management.projectEndDate.slice(
        0,
        10
      );
      this.management.leaseTermStart = this.management.leaseTermStart.slice(
        0,
        10
      );
      this.management.leaseTermEnd = this.management.leaseTermEnd.slice(0, 10);
    }
  },
  methods: {
    saveData() {
      //   if (this.assetId) {
      //     this.management.asset_id = this.assetId;
      //   }
      //   axios({
      //     method: "POST",
      //     url: "/asset/register/units/level2",
      //     data: this.management,
      //     headers: {
      //       token: localStorage.getItem("token"),
      //     },
      //   })
      //     .then((response) => {
      //       this.appLoading = false;
      //       if (response.data.status) {
      //         this.msg = response.data.msg;
      //         this.id = response.data.data._id;
      //         this.showSnackBar = true;
      this.$emit("stepper", {
        ref: "page2Tab",
        level: "tab-3",
        subLevel: 2,
        management: this.management,
      });
      this.$emit("stepper", {
        ref: "assetGetPage2",
        assetIdNew: this.id,
      });
      //   } else {
      //     this.msg = response.data.msg;
      //     this.showSnackBar = true;
      //   }
      // })
      // .catch((err) => {
      //   this.ServerError = true;
      //   console.log(err);
      // });
    },
  },
};
</script>